// import logo from './logo.svg';
import './App.css';
import MainPage from './frontend/Pages/MainPage';

function App() {
  return (
    <div className="App">
    <MainPage/>

    </div>
  );
}

export default App;
