import React from "react";
// import image from "morandr/src/images/selfImg.jpg";
import "../mainPage.css";


    function MainPage() {

            return (

            <div className= "Main">
        
                <header>
                    <h2>
                        Welcome to my website! <br />
                    </h2>
                    
                    <p>
                        There is a lot of work that needs to be done on here, <br />
                        because there is a lot to know about me!! 🙃
                    </p>

                    </header>

                <div></div>

          
             
            </div>



        )
    }

export default MainPage;